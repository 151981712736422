"use client";

import React, { useState } from "react";
import SwipeRightIcon from "@/app/components/icons/SwipeRightIcon";
import SwipeLeftIcon from "@/app/components/icons/SwipeLeftIcon";
import { useSwipeable } from "react-swipeable";
import Link from "next/link";

const BlogCarousel = ({ data, blogs, isMobile }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={`p-[20px] pr-[10px] md:p-[50px]`} onClick={onClick}>
        <SwipeRightIcon />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className={`p-[20px] pl-[10px] md:p-[50px]`} onClick={onClick}>
        <SwipeLeftIcon />
      </div>
    );
  };

  const handleCarouselNavigation = (type) => {
    setCurrentSlide((current) => {
      if (type === "prev") return current > 0 ? current - 1 : 0;
      if (type === "next")
        return current < blogs.length - 1 ? current + 1 : blogs.length - 1;
      return current;
    });
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleCarouselNavigation("next"),
    onSwipedRight: () => handleCarouselNavigation("prev"),
    preventScrollOnSwipe: true,
    trackMouse: true, // Optional: Allow mouse swiping for desktop
  });

  const mobileStyles = (idx) => {
    if (isMobile) {
      return idx === currentSlide ? "flex flex-col" : "hidden";
    }
    return "flex";
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return `${(dateObj.getDate() < 10 ? "0" : "") + dateObj.getDate()}.${(dateObj.getMonth() + 1 < 10 ? "0" : "") + (dateObj.getMonth() + 1)}.${dateObj.getFullYear()}`;
  };

  return (
    <div className={"flex flex-col text-center"}>
      <div className={"flex flex-col pb-[65px]"}>
        <p
          className={
            "uppercase font-montserrat font-normal text-[14px] leading-[22px] tracking-[2.8px] text-[#6D6662] mb-[10px] md:text-[28px] md:leading-[32px] md:tracking-[5.6px]"
          }
        >
          {data.blog_header_small}
        </p>
        <p
          className={
            "uppercase font-montserrat font-black text-[24px] leading-[24px] tracking-[2.4px] text-[#6D6662] px-[60px] md:text-[28px] md:leading-[32px] md:tracking-[5.6px] md:mb-[40px]"
          }
        >
          {data.blog_header_big}
        </p>
      </div>
      <div
        {...swipeHandlers}
        className="flex items-center justify-center text-left"
      >
        <PrevArrow onClick={() => handleCarouselNavigation("prev")} />
        {blogs.map((blog, idx) => (
          <div
            key={`blog-carousel-item-${idx}`}
            className={`
                          ${mobileStyles(idx)} items-center justify-center transition-transform duration-1000 ease-in-out
                          ${idx === currentSlide ? "scale-100 relative z-[20]" : "scale-100"}
                        `}
          >
            <div
              style={{
                backgroundImage: `url(${blog.thumbnail})`,
                backgroundSize: "cover",
              }}
              className={
                "flex flex-col rounded-[30px] border-[0.5px] border-[#D9D9D5] max-h-[350px] w-[282px] h-[242px] md:max-h-none md:h-[313px] md:mb-[10px]"
              }
            >
              <div
                className={
                  "flex justify-between items-center px-[40px] pb-[20px] mt-auto"
                }
              >
                <div
                  className={
                    "uppercase font-montserrat font-bold text-[14px] leading-[14px] text-center py-[5px] px-[10px] text-white rounded-[8px] bg-[#7C764C]"
                  }
                >
                  {blog.category}
                </div>
              </div>
            </div>
            <div
              className={
                "pl-[12px] pr-[20px] flex flex-col pt-[17px] text-left"
              }
            >
              <p
                className={
                  "font-inter font-normal text-[12px] leading-[14.5px] text-[#7C764C] pb-[14px]"
                }
              >
                {formatDate(blog.date)}
              </p>
              <p
                className={
                  "uppercase font-montserrat text-[18px] leading-[22px] tracking-[1.8px] text-[#777169] pb-[6px]"
                }
              >
                {blog.title.rendered}
              </p>
              <div
                className="mt-1 max-w-[360px] text-justify text-[#6C757D] h-[190px]  text-[14px] font-light leading-[20px]"
                dangerouslySetInnerHTML={{
                  __html: blog.acf.single_post_short_desc,
                }}
              ></div>
              <div>
                <Link
                  href={`/blog/${blog.slug}`}
                  className={
                    "font-inter font-bold text-[16px] leading-[20px] underline text-[#7C764C] w-full mt-[14px]"
                  }
                >
                  Pročitaj više...
                </Link>
              </div>
            </div>
          </div>
        ))}

        <NextArrow onClick={() => handleCarouselNavigation("next")} />
      </div>
    </div>
  );
};

export default BlogCarousel;
